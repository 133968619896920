<template>
    <div class="Image_preview">
        <!-- <el-image :src="imgUrl" fit="none"></el-image> -->
        <embed :src="imgUrl" type="">
    </div>
</template>
<script>
export default {
    name: "ImagePreview",
    data() {
        return {};
    },
    computed: {
        imgUrl() {
            // 截取文件名 this?.$route?.query?.url
            let name = this?.$route?.query?.name;
            document.title = name;
            return this?.$route?.query?.url;
        },
    },
};
</script>
<style lang="less" scoped>
.Image_preview {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Image_preview embed {
    width: 100%;
    height: 100%;
}
</style>